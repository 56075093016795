<template>
  <base-form-item :field="field">
    <div
        class="fieldInput--limitDb"
        v-if="meta && meta.maxLength"
    >
      <div class="fieldInput-maxLengthDb">
        <small
            class="help-block float-right"
            v-if="value"
        >
          {{ value.length }}/{{ meta.maxLength }}
        </small>
        <small
            class="help-block float-right"
            v-else
        >
          0/{{ meta.maxLength }}
        </small>
      </div>
    </div>
    <el-input
        :autocomplete="field.meta && field.meta.autocomplete ? field.meta.autocomplete : 'off'"
        :autofocus="field.meta && field.meta.autofocus ? field.meta.autofocus : false"
        :class="checkinputRequired(field.dbName) ? 'validate-error' : ''"
        :clearable="field.meta && field.meta.clearable ? field.meta.clearable : false"
        :disabled="field.meta && field.meta.disabled ? field.meta.disabled : false"
        :maxlength="field.meta && field.meta.maxLength ? field.meta.maxLength : ''"
        :minlength="field.meta && field.meta.min ? field.meta.min : ''"
        :name="field.dbName"
        :placeholder="field.meta && field.meta.placeholder ? field.meta.placeholder : ''"
        :prefix-icon="field.meta && field.meta.prefixIcon ? field.meta.prefixIcon : ''"
        :readonly="field.meta && field.meta.readonly ? field.meta.readonly : false"
        :resize="field.meta && field.meta.resize ? field.meta.resize : ''"
        :show-password="field.meta && field.meta.showPassword ? field.meta.showPassword : false"
        :size="field.meta && field.meta.size ? field.meta.size : ''"
        :step="field.meta && field.meta.step ? field.meta.step : ''"
        :suffix-icon="field.meta && field.meta.suffixIcon ? field.meta.suffixIcon : ''"
        :value="value"
        v-model="value"
    >
    </el-input>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  components: {BaseFormItem},
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.fieldInput--limitDb {
  position: relative;
}

.fieldInput-maxLengthDb {
  position: absolute;
  top: -40px;
  right: 5px;
}
</style>
